.Mui-selected{ border-bottom: none !important; }

.pricePage {
    margin-bottom: 100px;
}

.priceText {
   font-weight: 400;
   color: #4d1414;
}

.line {
    background-color: #e6c68f;
    width: 300px;
    margin-top: 9px;
    height: 2px;
}

.priceTextBox {
    justify-content: center;
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 30px;
    margin-bottom: 40px;
}

.priceList {
    display: flex;
    justify-content: space-between;
    width: 100%;
    gap: 50px;
}

.priceList li {
    list-style-type: none;
    font-size: 23px;
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.priceListBox h5 {
    font-weight: 400;
    margin-right: 10px;
}

.priceListBox span {
    color: #c0a877;
}

.pages {
    padding-left: 60px;
    padding-right: 60px;
}

.priceNameTag {
    display: flex;
    gap: 12px;
    justify-content: center;
    list-style-type: none;
    margin-bottom: 20px;
}

.priceNameTag li {
    border: #4d1414 1px solid;
    border-radius: 3px;
    cursor: pointer;
    font-size: 20px;
    padding: 5px 8px;
}

.priceNameTag li:hover {
    background-color: #dcd4d1;
    transition: 200ms;
}

.activeItem {
    background-color: lightgray;
}

@media screen and (max-width: 1280px) {

    .priceText {
        font-size: 37px;
    }

    .priceNameTag li {
        font-size: 25px;
    }

    .priceList li {
        font-size: 28px;
    }

    .priceList {
        flex-direction: column;
    }

    .pages {
        padding-left: 210px;
        padding-right: 210px;
    }
}

@media screen and (max-width: 955px) {

    .pages {
        padding-left: 100px;
        padding-right: 100px;
    }
}

@media screen and (max-width: 747px) {
    .pages {
        padding: 0;
        display: flex;
        justify-content: center;
    }

    .priceNameTag {
        flex-direction: column;
        justify-content: center;
        width: 300px;
    }

    .priceNamesBox {
        display: flex;
        justify-content: center;
    }
}

@media screen and (max-width: 520px) {
    .priceList li {
        font-size: 20px;
        padding: 0 20px 0;
    }

    .priceText {
        font-size: 25px;
    }
}