.works {
    padding-bottom: 120px;
    margin: 0;
}

.slider div {
    display: flex;
}

.works {
    padding-top: 20px;
    padding-bottom: 54px;
}

.line {
    background-color: #e6c68f;
    width: 300px;
    margin-top: 9px;
    height: 2px;
}

.worksTextBox {
    justify-content: center;
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-bottom: 30px;
}

.h1 {
    font-weight: 400;
    color: #4d1414;
}

.slideImg {
    width: 400px;
}

.children {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 20px;
}

@media screen and (max-width: 1280px) {
    .slideImg {
        width: 350px;
    }
}

@media screen and (max-width: 747px) {
    .children {
        flex-direction: column;
    }

    .slideImg {
        width: 400px;
    }
}
@media screen and (max-width: 520px) {
    .works h1 {
        font-size: 20px;
    }

    .slideImg {
       width: 340px;
    }
}
