.reviews {
    background-image: url("../../assets/headerBg.jpg");
    background-size: cover;
    background-repeat: no-repeat;
    background-position-y: 75%;
}

.reviewsBox {
    background-color: rgba(0, 0, 0, .6);
    color: #4d1414;
    padding: 130px 20px;
    justify-content: center;
    gap: 20px;
    display: flex;
}

.reviewPerson {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    text-align: center;
    background-color: #f5efe8;
    border-radius: 15px;
    padding: 18px 44px;
    width: 255px;
}

.reviewPerson img {
    border-radius: 50%;
    width: 100px;
    margin-bottom: 15px;
}

.reviewPerson h5 {
    font-weight: 500;
    font-size: 18px;
}

.reviewPerson p {
    font-weight: 400;
    margin-bottom: 19px;
}

@media screen and (max-width: 1080px) {
    .reviewsBox {
        align-items: center;
        flex-direction: column;
        padding: 65px 20px;
    }

    .reviewPerson {
        width: 350px;
    }

    .reviewPerson h5 {
        font-size: 23px;
    }

    .reviewPerson img {
        width: 111px;
    }

    .reviewPerson p {
        font-size: 21px;
    }
}

@media screen and (max-width: 520px) {
    .reviewPerson {
        width: 260px;
    }

    .reviewPerson h5 {
        font-size: 20px;
    }

    .reviewPerson img {
        width: 80px;
    }

    .reviewPerson p {
        font-size: 17px;
    }
}