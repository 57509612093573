.footer {
    display: flex;
    justify-content: space-between;
    background-color: #dcd4d1;
    color: #4d1414;

}

.footerTextBox {
    padding-top: 40px;
    padding-left: 20px;
    padding-bottom: 30px;
}

.footerTextBox h1 {
    font-weight: 500;
    font-size: 45px;
}

.footerTextBox h3 {
    font-weight: 400;
    font-size: 30px;
    margin-bottom: 50px;
}

.footerContactBlock img {
    width: 30px;
}

.contactBox {
    display:flex;
    align-items: center;
    gap: 10px;
    font-size: 24px;
    margin-bottom: 6px;
}

.telegram {
    width: 100px;
}

.map {
    width: 600px;
    height: 350px;
}

.telephone {
    color: #444444;
}

@media screen and (max-width: 1280px) {

    .footerTextBox {
        padding-bottom: 0;
    }
}

@media screen and (max-width: 1080px) {
    .footerTextBox h3 {
        width: 500px;
    }

    .map {
        width: 400px;
    }
}

@media screen and (max-width: 960px) {
    .footer {
        flex-direction: column;
    }

    .map {
        width: 100%;
    }
}

@media screen and (max-width: 747px) {
    .footerTextBox h3 {
        width: 330px;
    }
}

@media screen and (max-width: 520px) {
    .contactBox {
        font-size: 18px;
        gap: 5px;
    }

    .map {
        height: 200px;
    }
}