.header {
    background-image: url("../../assets/headerBg.jpg");
    background-size: cover;
    background-repeat: no-repeat;
}

.headerInfo {
    background-color: rgba(0, 0, 0, .6);
    color: white;
    padding-bottom: 160px;
}

.infoBlock {
    padding-left: 60px;
}

.infoBlock h1 {
    font-size: 45px;
    font-weight: 400;
    margin-bottom: 15px;
}

.infoBlock h2 {
    margin-top: 3px;
    font-weight: 400;
}

.mainIcon {
    margin-top: 10px;
    width: 70px;
    height: 70px;
}

.imgContact {
    padding-left: 70px;
}

.navUl {
    display: flex;
    gap: 50px;
    margin-left: 530px;
}

.navUl li {
    list-style-type: none;
    font-size: 19px;
    font-weight: 400;
    cursor: pointer;
}

.navUl li a {
    text-decoration: none;
    color: white;
}

.navUl li:hover {
    transition: 150ms;
    color: #a3a2a2;
}

.imgContact {
    display: flex;
    align-items: center;
}

.navbar {
    color: white;
    display: flex;
    align-items: center;
    padding-right: 60px;
    padding-left: 60px;
    padding-bottom: 150px;
    justify-content: space-between;
}

@media screen and (max-width: 1280px) {

    .infoBlock h1 {
        font-size: 53px;
    }

    .infoBlock p {
        font-size: 23px;
    }

    .navUl {
        margin-left: 320px;
    }

    .navbar {
        padding-bottom: 80px;
    }
}

@media screen and (max-width: 955px) {
    .navUl {
        margin-left: 50px;
        gap: 30px;
    }

    .imgContact img {
        width: 40px;
    }
}

@media screen and (max-width: 747px) {
    .navUl {
        display: none;
    }
}

@media screen and (max-width: 747px) {
      .headerInfo {
          padding-bottom: 50px;
      }

      .navbar {
          padding-right: 30px;
          padding-left: 30px;
          padding-bottom: 30px;
      }

      .infoBlock h1 {
          font-size: 35px;
      }

      .infoBlock p {
          font-size: 16px;
      }

      .infoBlock {
          padding-left: 30px;
      }
}